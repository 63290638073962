.bif-templates-page-wrapper {
    display: grid;
    grid-template-rows: max-content 1fr;
    max-height: 100%;

    &__header {
        position: sticky;
        top: 0;
    }
}
