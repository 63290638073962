@mixin small-phone {
    // NOTE: only used to satisfy button guidelines
    // https://www.figma.com/design/DY4vZXIIkC7JxZMr0HMKXB/Bifrost-UI-Library?node-id=6-2607&m=dev
    @media screen and (max-width: 353px) {
        @content;
    }
}

@mixin phone {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin tablet-portrait {
    @media screen and (min-width: 481px) and (max-width: 834px) and (pointer: fine) {
        @content;
    }

    @media screen and (min-width: 481px) and (orientation: portrait) and (pointer: coarse) {
        @content;
    }
}

@mixin tablet-portrait-up {
    @media screen and (min-width: 481px) {
        @content;
    }
}

@mixin tablet-portrait-down {
    @media screen and (max-width: 834px) {
        @content;
    }
}

@mixin tablet-landscape {
    @media screen and (min-width: 835px) and (max-width: 1200px) and (pointer: fine) {
        @content;
    }

    @media screen and (min-width: 835px) and (orientation: landscape) and (pointer: coarse) {
        @content;
    }
}

@mixin tablet-landscape-up {
    @media screen and (min-width: 835px) {
        @content;
    }
}

@mixin tablet-landscape-down {
    @media screen and (max-width: 1200px) and (pointer: fine) {
        @content;
    }

    @media screen and (pointer: coarse) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 1201px) and (pointer: fine) {
        @content;
    }
}
