@use 'typography';
@use 'devices';

@import '@bifrost/web-components/dist/index.css';
@import '@bifrost/web-templates/dist/index.css';

.bcp-shell {
    display: grid;
    height: 100vh;
    background-color: var(--cinemo-colors-black-900);
    color: var(--cinemo-colors-black-100);
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-areas:
        'banner banner'
        'navigation slot';

    @include typography.body-primary;

    &__banner {
        grid-area: banner;
    }

    &__navigation {
        grid-area: navigation;
    }

    &__slot {
        grid-area: slot;
        position: relative;
        overflow-y: auto;
    }

    @include devices.desktop {
        &__navigation {
            height: 100%;
            overflow-y: auto;
        }
    }
}
