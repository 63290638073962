@mixin h1 {
    font-family: Inter, sans-serif;
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 4rem;
}

@mixin h2 {
    font-family: Inter, sans-serif;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.2rem;
}

@mixin h3 {
    font-family: Inter, sans-serif;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.8rem;
}

@mixin h4 {
    font-family: Inter, sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.4rem;
}

@mixin h5 {
    font-family: Inter, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
}

@mixin h6 {
    font-family: Inter, sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
}

@mixin page-title {
    font-family: Inter, sans-serif;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.2rem;
}

@mixin button {
    font-family: Inter, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.2rem;
}

@mixin body-primary {
    font-family: Inter, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
}

@mixin body-secondary {
    font-family: Inter, sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
}

@mixin caption-primary {
    font-family: Inter, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
}

@mixin caption-secondary {
    font-family: Inter, sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.4rem;
}
