.bif-templates-main-page {
    display: grid;
    padding: var(--cinemo-sizes-gap-s) var(--cinemo-sizes-keyline-xs) 0;
    gap: var(--cinemo-sizes-gap-m);

    &__horizontal-list {
        display: grid;
        position: relative;
        justify-content: center;

        &__list {
            position: relative;
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-shrink: 0;
            flex-wrap: nowrap;
            gap: var(--cinemo-sizes-keyline-m);
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: calc(-1 * var(--cinemo-sizes-keyline-xs));
            width: 8.8rem;
            height: 8.8rem;
            background: linear-gradient(85deg, rgba(23, 23, 23, 0) 0%, var(--cinemo-colors-black-900) 100%);
            pointer-events: none;
        }
    }

    &__sections {
        display: grid;
        gap: var(--cinemo-sizes-gap-xxs);

        &__section {
            display: grid;
            padding: var(--cinemo-sizes-gap-s) var(--cinemo-sizes-keyline-m);
            background-color: var(--cinemo-colors-black-850);
            gap: var(--cinemo-sizes-gap-s);
            border-radius: var(--cinemo-sizes-radius-xxl);

            &__contents {
                display: grid;
                gap: var(--cinemo-sizes-gap-xxs);
            }
        }
    }
}
